import React, { useState, useEffect } from 'react';
import Excel from "../assets/excel.svg";
import Info from "../assets/Info.png";
import MainContent from '../components/MainContent';
import SearchIcon from "../assets/search-icon.svg";
import Pagination from '../components/Pagination';
import { searchByExcel, getBatchProgress } from '../apiClient';
import PopupGuideline from '../components/PopupGuideline'; 

const BatchSearch = () => {
  const [files, setFiles] = useState([]); 
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [taskId, setTaskId] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [progressStatus, setProgressStatus] = useState('');
  const [progressData, setProgressData] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 20;
  const [ShowPopup, setShowPopup] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); 
    setFiles(selectedFiles); 
    setUploadedFiles([...uploadedFiles, ...selectedFiles.map(file => ({ name: file.name, status: '待上传', taskId: null }))]);
  };
  
  const handleUpload = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      setUploadStatus('Please select files.');
      return;
    }
  
    const updatedFiles = [...uploadedFiles];
    let lastTaskId = '';
  
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('file', files[i]);
  
      try {
        const response = await searchByExcel(files[i]);
        lastTaskId = response.batchSearchTaskId;
        updatedFiles[i] = { ...updatedFiles[i], status: '上传成功', taskId: lastTaskId };
      } catch (error) {
        updatedFiles[i] = { ...updatedFiles[i], status: '上传失败' };
      }
    }
  
    setUploadedFiles(updatedFiles);
    setTaskId(lastTaskId);
    if (lastTaskId) {
      startPolling(lastTaskId);
    }
  };
  

  const startPolling = (taskId) => {
    const id = setInterval(async () => {
      try {
        const response = await getBatchProgress(taskId);
        const progressText = response.taskProgress === '100%' ? '100% (显示completed 才会出现数据)' : response.taskProgress;
        setProgressStatus(`任务进度: ${progressText}`);
        if (response.results) {
          setProgressData(response.results);
          setTotalPages(Math.ceil(response.results.length / itemsPerPage));
          clearInterval(id);
        }
      } catch (error) {
        console.error(error);
      }
    }, 5000); // Poll every 5 seconds
    setIntervalId(id);
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  const handlePageChange = (event, page) => {
    event.preventDefault();
    setCurrentPage(page);
  };

  const paginatedData = progressData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const togglePopup = () => {
    setShowPopup(!ShowPopup);
  };

  const handleDelete = () => {
    setShowPopup(); 
  };

  return (
    <div>
      <MainContent />
      <div className='container'>
        <div className='main-content mb-2'>
          <div className="content">
            <div className="upload-container">
              <form className="upload-button" onSubmit={handleUpload}>
                <input type="file" accept=".xlsx, .xls, .csv" id="fileInput" onChange={handleFileChange} multiple required style={{ display: 'none' }} />
                <label htmlFor="fileInput" className="custom-file-upload">上传Excel文件以进行搜索</label>
                <button className="upload-button-new" type="submit">确定</button>
              </form>
            </div>
            <div className="file-list">
              {uploadedFiles.map((file, index) => (
                <div className="file-item" key={index}>
                  <img src={Excel} alt="Excel" />
                  <span className="file-name">{file.name} - {file.status}</span>
                </div>
              ))}
            </div>

            <p>{uploadStatus}</p>
          </div>
        </div>

        {/* Trigger button */}
        <div onClick={togglePopup} style={{ cursor: 'pointer', color: '#5fb1cc', textDecoration: 'none' }}>
          <img src={Info} alt="template" />
          <span> Excel规范指南</span>
        </div>

        {/* Using PopupGuideline component */}
        <PopupGuideline 
          showPopup={ShowPopup} 
          togglePopup={togglePopup}
          handleDelete={handleDelete}
        />

        <form onSubmit={(e) => { e.preventDefault(); startPolling(taskId); }}>
          <div className="d-flex mt-3">
            <div className="dropdown-container">
              <input
                type="text"
                placeholder="输入 Task ID"
                value={taskId}
                onChange={(e) => setTaskId(e.target.value)}
                required
              />
            </div>
            <button className="search-btn" type="submit"><img src={SearchIcon} alt="Search" /></button>
          </div>
        </form>

        <p className='mt-3'>{progressStatus}</p>

        {progressData.length > 0 && (
          <div>
            <table className="custom-table mt-3">
              <thead>
                <tr>
                  <th>纯度</th>
                  <th>类型</th>
                  <th>规格</th>
                  <th>供应商</th>
                  <th>品牌</th>
                  <th>同行价¥</th>
                  <th>促销价¥</th>
                  <th>是否现货</th>
                  <th>数据日期</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((result, index) => (
                  <tr key={index}>
                    <td>{result.purity}</td>
                    <td>{result.type}</td>
                    <td>{result.size}</td>
                    <td>{result.competitor}</td>
                    <td>{result.brand}</td>
                    <td>{result.marketPrice}</td>
                    <td>{result.promoPrice}</td>
                    <td>{result.stock}</td>
                    <td>{result.dataDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BatchSearch;
