import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'http://jk-api-competitor-bi-mw-prod.jk-data.com:3000',
  headers: {
    'Content-Type': 'application/json'
  }
});

apiClient.interceptors.request.use(request => {
  console.log('Starting Request', request);
  return request;
}, error => {
  return Promise.reject(error);
});

apiClient.interceptors.response.use(response => {
  console.log('Response:', response);
  return response;
}, error => {
  console.error('Response Error:', error);
  return Promise.reject(error);
});

export default apiClient;

export const searchByCas = async (cas, filters) => {
  const response = await apiClient.post('/search/cas', { cas, filters });
  return response.data;
};

export const searchByProductCode = async (productCode, filters) => {
  const response = await apiClient.post('/search/productCode', { productCode, filters });
  return response.data;
};

export const searchByExcel = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await apiClient.post('/search/excel', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const getBatchProgress = async (taskId) => {
  const response = await apiClient.post('/search/getBatchProgress', { taskId });
  return response.data;
};
