import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const maxPagesToShow = 5;

  const getPageNumbers = () => {
    let startPage, endPage;
    if (totalPages <= maxPagesToShow) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const middle = Math.floor(maxPagesToShow / 2);
      if (currentPage <= middle) {
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + middle >= totalPages) {
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - middle;
        endPage = currentPage + middle;
      }
    }
    return [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
  };

  return (
    <div className="pagination mt-4">
      <a href="#" className={currentPage === 1 ? 'disabled' : ''} onClick={(event) => handlePageChange(event, 1)}>&laquo;&laquo;</a>
      <a href="#" className={currentPage === 1 ? 'disabled' : ''} onClick={(event) => handlePageChange(event, currentPage - 1)}>&laquo;</a>
      {currentPage > Math.floor(maxPagesToShow / 2) + 1 && <span style={{ paddingTop: '10px',margin: '0 10px' }}>...</span>}
      {getPageNumbers().map((page) => (
        <a
          key={page}
          href="#"
          className={currentPage === page ? 'active' : ''}
          onClick={(event) => handlePageChange(event, page)}
        >
          {page}
        </a>
      ))}
      {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <span href="#" style={{ paddingTop: '10px',margin: '0 10px'  }}>...</span>}
      <a href="#" className={currentPage === totalPages ? 'disabled' : ''} onClick={(event) => handlePageChange(event, currentPage + 1)}>&raquo;</a>
      <a href="#" className={currentPage === totalPages ? 'disabled' : ''} onClick={(event) => handlePageChange(event, totalPages)}>&raquo;&raquo;</a>
    </div>
  );
};

export default Pagination;
