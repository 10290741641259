import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ProductNo from './pages/Product-no';
import BatchSearch from './pages/Batch-search';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
       
        <div className="content-big-wrap">
          <Routes>
            <Route path="/" element={<Navigate to="/Home" />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Product-no" element={<ProductNo />} />
            <Route path="/Batch-search" element={<BatchSearch />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
