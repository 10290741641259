import React from 'react';
import { NavLink } from 'react-router-dom';

const MainContent = ({ togglePopup }) => (
  <div className="container">
    <h2 className="text-center">同行比价系统</h2>
    <p className="text-center">同行比价系统xxxxxxxxxxxxxxxxxxxxxxxxxxxxx</p>
    <ul className="nav nav-tabs mt-5" id="myTab" role="tablist">
      <li className="nav-item">
        <NavLink 
          className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
          to="/Home" 
          role="tab" 
          aria-controls="CAS搜索"
        >
          CAS搜索
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink 
          className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
          to="/Product-no" 
          role="tab" 
          aria-controls="Product No."
        >
          Product No.
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink 
          className={({ isActive }) => "nav-link" + (isActive ? " active" : "")}
          to="/Batch-search" 
          role="tab" 
          aria-controls="批量搜索"
        >
          批量搜索
        </NavLink>
      </li>
    </ul>
  </div>
);

export default MainContent;
