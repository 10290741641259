
import React, { useState, useEffect } from 'react';
import DropdownArrow from "../assets/arrow-btn.svg";

const FilterDropdown = ({
  handlePurityChange,
  handleTypeChange,
  handleNoLimit,
  handleMinValueChange,
  handleMaxValueChange,
  handleUnitChange,
  handleAllCompetitorsChange,
  handleCompetitorsChange,
  clearCompetitorsFilters,
  handleAllBrandsChange,
  handleBrandsChange,
  clearBrandsFilters,
  handleDateChange,
  clearDateFilter,
  showDropdown,
  setShowDropdown,
  selectedPurity,
  selectedType,
  selectedMinValue,
  selectedMaxValue,
  selectedUnit,
  selectedCompetitors,
  allCompetitorsSelected,
  selectedBrands,
  allBrandsSelected,
  selectedDate
}) => {
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div>
      <button className="btn wrap-bg d-flex justify-content-between" type="button" onClick={toggleDropdown}>
        <div className="d-inline-block">
          <h3>数据初步筛选</h3>
          <p className="note">缩小搜索范围，可以大幅缩短搜索时间</p>
        </div>
        <div className="d-inline-block pt-3">
          <img src={DropdownArrow} style={{ width: '14px' }} alt="Toggle Dropdown" />
        </div>
      </button>

      {showDropdown && (
        <div className="card card-body">
          <div className="d-flex">
            <div className="left">纯度</div>
            <div className="right">
              <label className="radio-label">
                <input type="radio" name="purity" value="unlimited" onChange={handlePurityChange} checked={selectedPurity === 'unlimited'} />
                <span>不限</span>
              </label>
              {['80%', '85%', '90%', '95%', '96%', '97%', '98%', '99%'].map((purity, index) => (
                <label className="radio-label" key={index}>
                  <input type="radio" name="purity" value={purity} onChange={handlePurityChange} checked={selectedPurity === purity} />
                  <span>{purity}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="d-flex mt-2">
            <div className="left">类型</div>
            <div className="right" style={{ display: 'flex'}}>
            <label 
                className="radio-label" 
                style={{ 
                  color: '#888', 
                  display: 'flex', 
                  alignItems: 'center',
                  cursor: 'not-allowed',
                  marginBottom: '4px'
                }}
              >
                <input 
                  type="radio" 
                  name="type" 
                  value="unlimited" 
                  onChange={handleTypeChange} 
                  checked={selectedType === 'unlimited'} 
                  disabled={true} 
                />
                <span>不限</span>
              </label>
              {['TCI原装', 'S-A原装'].map((type, index) => (
                <label 
                  className="radio-label" 
                  key={index} 
                  style={{ 
                    color: '#888', 
                    display: 'flex', 
                    alignItems: 'center',
                    cursor: 'not-allowed',
                    marginBottom: '4px' 
                  }}
                >
                  <input 
                    type="radio" 
                    name="type" 
                    value={type} 
                    onChange={handleTypeChange} 
                    checked={selectedType === type}
                    disabled={true} 
                  />
                  <span>{type}</span>
                 
                </label>
              ))}
            </div>
          </div>





          <div className="d-flex mt-2">
            <div className="left">规格</div>
            <div className="right">
              <button className="unlimitedButton" onClick={handleNoLimit}>不限</button>
              <div className="d-inline-block custom-select-wrapper">
                <div className="input-group">
                  <input type="text" className="form-control" name="min" value={selectedMinValue} onChange={handleMinValueChange} />
                </div>
              </div>
              <div className="d-inline-block"> - </div>
              <div className="d-inline-block custom-select-wrapper">
                <div className="input-group">
                  <input type="text" className="form-control" name="max" value={selectedMaxValue} onChange={handleMaxValueChange} />
                </div>
              </div>
              <div className="custom-select-wrapper">
                <select id="customSelect3" className="custom-select" value={selectedUnit} onChange={handleUnitChange}>
                  <option value="g">g</option>
                  <option value="mg">mg</option>
                  <option value="mL">mL</option>
                </select>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex">
              <div className="left">供应商</div>
              <div className="right">
                <label className="radio-label">
                  <input type="checkbox" name="competitors" value="unlimited" checked={allCompetitorsSelected} onChange={handleAllCompetitorsChange} />
                  <span>不限</span>
                </label>
                {['energy', '阿拉丁', '麦克林', 'Sigma-Aldrich', 'JK'].map((label, index) => (
                  <div className="d-inline-block" key={index}>
                    <input
                      type="checkbox"
                      id={`competitors${index}`}
                      name={`competitors${index}`}
                      className="custom-checkbox-input"
                      value={label}
                      checked={selectedCompetitors.includes(label)}
                      onChange={handleCompetitorsChange}
                    />
                    <label htmlFor={`competitors${index}`} className="custom-checkbox-label-confirm mr-2">{label}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex">
              <a href="#" className="blue" onClick={clearCompetitorsFilters}>清空</a>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex">
              <div className="left">品牌</div>
              <div className="right">
                
                <label className="radio-label">
                  <input type="checkbox" name="brands" value="unlimited" checked={allBrandsSelected} onChange={handleAllBrandsChange} />
                  <span>不限</span>
                </label>
                {['Thermo Scientific', '安耐吉', 'J&K', 'Tmstandard-有机单标', 'Apollo', 'TRC', 'Sigma-Aldrich'].map((label, index) => (
                  <div className="d-inline-block" key={index}>
                    <input
                      type="checkbox"
                      id={`brands${index}`}
                      name={`brands${index}`}
                      className="custom-checkbox-input"
                      value={label}
                      checked={selectedBrands.includes(label)}
                      onChange={handleBrandsChange}
                    />
                    <label htmlFor={`brands${index}`} className="custom-checkbox-label-confirm mr-2">{label}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex">
              <a href="#" className="blue" onClick={clearBrandsFilters}>清空</a>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex">
              <div className="left">数据日期</div>
              <div className="right">
                <button className="unlimitedButton" onClick={clearDateFilter}>不限</button>
                <div className="d-inline-block custom-select-wrapper-date">
                  <input
                    type="date"
                    className="form-control"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <a href="#" className="blue" onClick={clearDateFilter}>清空</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
