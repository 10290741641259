import React from 'react';
import closeIcon from "../assets/close.svg";
import '../styles/style.css';

const Popup = ({ showPopup, togglePopup, handleDelete }) => {
  if (!showPopup) return null; // 仅在 showPopup 为 true 时显示

  return (
    <div id="popup" className="popup">
      <div className="popup-content">
        <span className="close-btn" onClick={togglePopup}>
          <img src={closeIcon} width="22px" alt="Close" />
        </span>
        <h2>确认删除当前产品</h2>
        <p>删除后将无法进行恢复</p>
        <button className="confirm-btn mt-2" onClick={handleDelete}>确定</button>
        <a href="#" className="return-link" onClick={togglePopup}>返回</a>

      </div>
    </div>
  );
};

export default Popup;
