
import React from 'react';
import closeIcon from "../assets/close.svg";
import '../styles/style.css';

const PopupGuideline = ({ showPopup, togglePopup, handleDelete }) => {
    if (!showPopup) return null; 
  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-btn" onClick={togglePopup}>
          <img src={closeIcon} width="22px" alt="Close" />
        </span>
        <h2>Excel规范指南</h2>
        <p style={{ color: '#686868', fontSize: '16px', textAlign: 'left' }}>
        在您的 Excel 文件中，请按照以下规范填写数据，确保每个字段的格式和内容都符合要求。具体要求如下：
        </p>
        <ul style={{ textAlign: 'left', lineHeight: '2rem' }}>
        <li>在Excel中可以设置7列，标题分别为「CAS」「ProductCode」「Competitor」「DataDate」「Purity」「Size」「Brand」。</li>
        <li>CAS 与 ProductCode 二者之间必须至少填写一个。如填写ProductCode需提供Competitor，其余按需选填。</li>
        <li>CAS 登记号由最多 10 位数字组成，用连字符分为三组。从左侧开始，数字的前半部分包含 2 到 7 位数字；第二部分有 2 位数字。最后一个元素是单个校验位。最右边的数字作为校验位，以确保整个数字合法且唯一。例如，咖啡因的 CAS 登记号为 58-08-2。</li>
        <li>ProductCode 只能输入英文和数字。</li>
        <li>Competitor 需填写其中一个名称：energy、阿拉丁、麦克林、Sigma-Aldrich、JK。</li>
        <li>DataDate 请按照日期与时间格式填写（例如“2024-08-06 16:48:26”）。</li>
        <li>Purity 应填写0-100之间的数字，可加小数点，后面加上“%”符号（例如“98%”）。</li>
        <li>Size 应填写0-100之间的数字，可加小数点，后接重量单位 - mg、g、kg、ml、mL（例如“100g”）。</li>
        <li>Brand 需填写其中一个名称：Thermo Scientific、安耐吉、J&K、Tmstandard-有机单标、Apollo、TRC、Sigma-Aldrich。</li>
        </ul>





        <button className="confirm-btn mt-2" onClick={handleDelete}>确定</button>
        <a href="#" className="return-link" onClick={togglePopup}>返回</a>

      </div>
    </div>
  );
};

export default PopupGuideline;
