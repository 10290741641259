import React, { useState, useEffect } from 'react';
import MainContent from '../components/MainContent';
import FilterDropdown from "../components/FilterDropdown";
import Popup from "../components/Popup";
import Pagination from "../components/Pagination";
import DataTable from "../components/DataTable"; 
import '../styles/style.css';
import download from "../assets/download.png";
import close from "../assets/close.svg";
import SearchIcon from "../assets/search-icon.svg";
import { searchByProductCode } from '../apiClient';
import * as XLSX from 'xlsx';  

// Helper
const getUniqueCompetitors = (data) => {
  const competitors = data.map(item => item.competitor);
  return [...new Set(competitors)];
};

const getUniqueBrands = (data) => {
  const brands = data.map(item => item.brand);
  return [...new Set(brands)];
};

const parseSizeInput = (input) => {
  const sizeMatch = input.match(/([\d.]+)(mg|g|kg|ml|mL)/i);
  if (sizeMatch) {
    return {
      size: parseFloat(sizeMatch[1]),
      unit: sizeMatch[2].toLowerCase() 
    };
  }
  return { size: 0, unit: 'mg' }; 
};

const ProductNo = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPurity, setSelectedPurity] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedMinValue, setSelectedMinValue] = useState('');
  const [selectedMaxValue, setSelectedMaxValue] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('g');
  const [selectedCompetitors, setSelectedCompetitors] = useState([]);
  const [radioSelectedCompetitor, setRadioSelectedCompetitor] = useState('');
  const [allCompetitorsSelected, setAllCompetitorsSelected] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [allBrandsSelected, setAllBrandsSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [purityInput, setPurityInput] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sizeInput, setSizeInput] = useState('');
  const [marketPriceInput, setMarketPriceInput] = useState('');
  const [marketPriceSortDirection, setMarketPriceSortDirection] = useState('asc');
  const [promoPriceInput, setPromoPriceInput] = useState('');
  const [promoPriceSortDirection, setPromoPriceSortDirection] = useState('asc');
  const [selectedCompetitor, setSelectedCompetitor] = useState('不限');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [FilterDate, setFilterDate] = useState('');
  const [selectedStockStatus, setSelectedStockStatus] = useState(''); 
  const [currentPage, setCurrentPage] = useState(1); 
  const itemsPerPage = 20; 


  const handleRadioCompetitorChange = (event) => {
    const value = event.target.value;
    setRadioSelectedCompetitor(value);
    const filteredData = searchResults.filter(item => item.competitor === value);
    setFilteredData(filteredData);
  };

  const handlePurityChange = (event) => {
    const value = event.target.value;
    setSelectedPurity(value);
  };

  const handleTypeChange = (event) => {
    const value = event.target.value;
    setSelectedType(value);
  };

  const handleMinValueChange = (event) => {
    setSelectedMinValue(event.target.value);
  };

  const handleMaxValueChange = (event) => {
    setSelectedMaxValue(event.target.value);
  };

  const handleUnitChange = (event) => {
    setSelectedUnit(event.target.value);
  };

  const handleNoLimit = () => {
    setSelectedMinValue('');
    setSelectedMaxValue('');
    setSelectedUnit('g');
  };

  const handleAllCompetitorsChange = () => {
    if (allCompetitorsSelected) {
      setSelectedCompetitors([]);
    } else {
      setSelectedCompetitors(['energy', '阿拉丁', '麦克林', 'Sigma-Aldrich', 'JK']);
    }
    setAllCompetitorsSelected(!allCompetitorsSelected);
  };

  const handleCompetitorsChange = (event) => {
    const value = event.target.value;
    const newSelectedCompetitors = selectedCompetitors.includes(value)
      ? selectedCompetitors.filter(item => item !== value)
      : [...selectedCompetitors, value];

    setSelectedCompetitors(newSelectedCompetitors);
    setAllCompetitorsSelected(newSelectedCompetitors.length === 3);
  };

  const clearCompetitorsFilters = (event) => {
    event.preventDefault();
    setSelectedCompetitors([]);
    setAllCompetitorsSelected(false);
  };

  const handleAllBrandsChange = () => {
    if (allBrandsSelected) {
      setSelectedBrands([]);
    } else {
      setSelectedBrands(['Thermo Scientific', '安耐吉', 'J&K', 'Tmstandard-有机单标', 'Apollo', 'TRC', 'Sigma-Aldrich']);
    }
    setAllBrandsSelected(!allBrandsSelected);
  };

  const handleBrandsChange = (event) => {
    const value = event.target.value;
    const newSelectedBrands = selectedBrands.includes(value)
      ? selectedBrands.filter(item => item !== value)
      : [...selectedBrands, value];

    setSelectedBrands(newSelectedBrands);
    setAllBrandsSelected(newSelectedBrands.length === 7);
  };

  const clearBrandsFilters = (event) => {
    event.preventDefault();
    setSelectedBrands([]);
    setAllBrandsSelected(false);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const clearDateFilter = (event) => {
    event.preventDefault();
    setSelectedDate('');
  };

  const handleStockStatusChange = (event) => {
    setSelectedStockStatus(event.target.value);
  };

  useEffect(() => {
    let filtered = searchResults;
    if (selectedPurity && selectedPurity !== 'unlimited') {
      filtered = filtered.filter(item => item.purity === selectedPurity);
    }
    if (selectedType && selectedType !== 'unlimited') {
      filtered = filtered.filter(item => item.type === selectedType);
    }
    if (selectedMinValue !== '' || selectedMaxValue !== '') {
      const min = selectedMinValue ? parseFloat(selectedMinValue) : -Infinity;
      const max = selectedMaxValue ? parseFloat(selectedMaxValue) : Infinity;
      filtered = filtered.filter(item => {
        const size = parseFloat(item.size);
        return size >= min && size <= max && item.size.includes(selectedUnit);
      });
    }
    if (selectedCompetitors.length > 0 && !allCompetitorsSelected) {
      filtered = filtered.filter(item => selectedCompetitors.includes(item.competitor));
    }
    if (radioSelectedCompetitor !== '不限') { 
      filtered = filtered.filter(item => item.competitor === radioSelectedCompetitor); 
    }
    if (selectedBrands.length > 0 && !allBrandsSelected) {
      filtered = filtered.filter(item => selectedBrands.includes(item.brand));
    }
    if (selectedDate !== '') {
      filtered = filtered.filter(item => new Date(item.dataDate) >= new Date(selectedDate));
    }
    if (selectedStockStatus) {
      filtered = filtered.filter(item => selectedStockStatus === '是' ? item.stock > 0 : item.stock <= 0 || item.stock === null || item.stock === '');
    }
    setFilteredData(filtered);
}, [selectedPurity, selectedType, selectedMinValue, selectedMaxValue, selectedUnit, selectedCompetitors, allCompetitorsSelected, selectedBrands, allBrandsSelected, selectedDate, selectedStockStatus, searchResults, radioSelectedCompetitor]); 

  const fetchDataByProductCode = async (ProductCode, filters) => {
    try {
      const data = await searchByProductCode(ProductCode, filters);
      setSearchResults(data);
      setFilteredData(data);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleSearch = () => {
    if (searchInput) {
      fetchDataByProductCode(searchInput, {
        purity: selectedPurity ? [selectedPurity] : [],
        type: selectedType ? [selectedType] : [],
        specification: {
          min: selectedMinValue,
          max: selectedMaxValue,
          unit: selectedUnit
        },
        brands: selectedBrands,
        competitors: selectedCompetitors,
        dataDate: selectedDate
      });
    }
  };

  const handleDelete = () => {
    setSelectedPurity('');
    setSelectedType('');
    setSelectedMinValue('');
    setSelectedMaxValue('');
    setSelectedUnit('g');
    setSelectedCompetitors([]);
    setAllCompetitorsSelected(false);
    setSearchInput('');
    setSearchResults([]);
    setSelectedBrands([]);
    setAllBrandsSelected(false);
    setSelectedDate('');
    setFilteredData([]);
    setShowPopup(false);
  };

  const handlePurityInputChange = (event) => {
    const value = event.target.value;
    setPurityInput(value);

    if (value === '') {
        setFilteredData(searchResults);
    } else {
        const filteredData = searchResults.filter(item => parseFloat(item.purity) >= parseFloat(value));
        setFilteredData(filteredData.length > 0 ? filteredData : []);
    }
  };

  const sortByPurity = () => {
    const sortedResults = [...filteredData].sort((a, b) => {
        const purityA = parseFloat(a.purity);
        const purityB = parseFloat(b.purity);
        return sortDirection === 'asc' ? purityA - purityB : purityB - purityA;
    });
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setFilteredData(sortedResults);
  };

  const handleMarketPriceInputChange = (event) => {
    const value = event.target.value;
    setMarketPriceInput(value);
    filterAndSortMarketPriceData(value, marketPriceSortDirection);
  };

  const handleMarketPriceSort = () => {
    const newSortDirection = marketPriceSortDirection === 'asc' ? 'desc' : 'asc';
    setMarketPriceSortDirection(newSortDirection);
    filterAndSortMarketPriceData(marketPriceInput, newSortDirection);
  };

  const filterAndSortMarketPriceData = (price, direction) => {
    let filtered = searchResults;

    if (price !== '') {
        filtered = filtered.filter(item => item.marketPrice.toString().startsWith(price));
    }

    const sorted = filtered.sort((a, b) => direction === 'asc' ? parseFloat(a.marketPrice) - parseFloat(b.marketPrice) : parseFloat(b.marketPrice) - parseFloat(a.marketPrice));
    setFilteredData(sorted.length > 0 ? sorted : []);
  };

  const handlePromoPriceInputChange = (event) => {
    const value = event.target.value;
    setPromoPriceInput(value);
    filterAndSortPromoPriceData(value, promoPriceSortDirection);
  };

  const handlePromoPriceSort = () => {
    const newSortDirection = promoPriceSortDirection === 'asc' ? 'desc' : 'asc';
    setPromoPriceSortDirection(newSortDirection);
    filterAndSortPromoPriceData(promoPriceInput, newSortDirection);
  };

  const filterAndSortPromoPriceData = (price, direction) => {
    let filtered = searchResults;

    if (price !== '') {
        filtered = filtered.filter(item => item.promoPrice.toString().startsWith(price));
    }

    const sorted = filtered.sort((a, b) => direction === 'asc' ? parseFloat(a.promoPrice) - parseFloat(b.promoPrice) : parseFloat(b.promoPrice) - parseFloat(a.promoPrice));
    setFilteredData(sorted.length > 0 ? sorted : []);
  };

  const handleSizeInputChange = (event) => {
    const value = event.target.value;
    setSizeInput(value);

    if (value) {
        const parsedInputSize = parseSizeInput(value);
        const filteredResults = searchResults.filter(item => {
            const parsedItemSize = parseSizeInput(item.size);
            return parsedItemSize.unit === parsedInputSize.unit && parsedItemSize.size >= parsedInputSize.size;
        });
        setFilteredData(filteredResults.length > 0 ? filteredResults : []);
    } else {
        setFilteredData(searchResults);
    }
  };

  const sortBySize = () => {
    const sortedResults = [...filteredData].sort((a, b) => {
        const sizeAValue = parseFloat(a.size);
        const sizeBValue = parseFloat(b.size);
        const sizeAUnit = a.size.match(/[a-zA-Z]+/g)[0];
        const sizeBUnit = b.size.match(/[a-zA-Z]+/g)[0];

        if (sizeAUnit === sizeBUnit) {
            return sortDirection === 'asc' ? sizeAValue - sizeBValue : sizeBValue - sizeAValue;
        } else {
            return sortDirection === 'asc' ? sizeAUnit.localeCompare(sizeBUnit) : sizeBUnit.localeCompare(sizeAUnit);
        }
    });

    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setFilteredData(sortedResults);
  };

  const handleCompetitorChange = (event) => {
    const value = event.target.value;
    setSelectedCompetitor(value);

    if (value && value !== '不限') {
        setFilteredData(prevFilteredData => prevFilteredData.filter(item => item.competitor === value));
    } else {
        setFilteredData(searchResults);
    }
  };

  const handleBrandChange = (event) => {
    const value = event.target.value;
    setSelectedBrand(value);

    if (value && value !== '') {
        setFilteredData(prevFilteredData => prevFilteredData.filter(item => item.brand === value));
    } else {
        setFilteredData(searchResults);
    }
  };

  const handleFilterDateChange = (event) => {
    const value = event.target.value;
    setFilterDate(value);
  
    if (value === '') {
      setFilteredData(searchResults);
    } else {
      const filteredData = searchResults.filter(item => {
        return new Date(item.dataDate) >= new Date(value);
      });
      setFilteredData(filteredData);
    }
  };

  // 获取当前页的数据
  const currentItems = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // 计算总页数
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (event, pageNumber) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FilteredData");
    XLSX.writeFile(workbook, "Product No.xlsx");
  };

  
  return (
    <div>
      <MainContent togglePopup={togglePopup} />
      <div className='container'>
        <div className='main-content'>
          <div>
            <FilterDropdown
              handlePurityChange={handlePurityChange}
              handleTypeChange={handleTypeChange}
              handleNoLimit={handleNoLimit}
              handleMinValueChange={handleMinValueChange}
              handleMaxValueChange={handleMaxValueChange}
              handleUnitChange={handleUnitChange}
              handleAllCompetitorsChange={handleAllCompetitorsChange}
              handleCompetitorsChange={handleCompetitorsChange}
              clearCompetitorsFilters={clearCompetitorsFilters}
              handleAllBrandsChange={handleAllBrandsChange}
              handleBrandsChange={handleBrandsChange}
              clearBrandsFilters={clearBrandsFilters}
              handleDateChange={handleDateChange}
              clearDateFilter={clearDateFilter}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              selectedPurity={selectedPurity}
              selectedType={selectedType}
              selectedMinValue={selectedMinValue}
              selectedMaxValue={selectedMaxValue}
              selectedUnit={selectedUnit}
              selectedCompetitors={selectedCompetitors}
              allCompetitorsSelected={allCompetitorsSelected}
              selectedBrands={selectedBrands}
              allBrandsSelected={allBrandsSelected}
              selectedDate={selectedDate}
            />
          </div>
          <div className="radio-form mt-4">
            <div className="label-container">
              <div className="product-name">
                Product No. 來源 <br></br><span className="red">* 必选</span>:
              </div>
              <div className="vertical-line"></div>
            </div>
            <div className="radio-group">
              <label className="custom-radio">
                <input 
                  type="radio" 
                  name="source" 
                  value="energy" 
                  checked={radioSelectedCompetitor === 'energy'} // Bind state
                  onChange={handleRadioCompetitorChange} // Handle change
                />
                <span className="radio-circle"></span>
                energy
              </label>
              <label className="custom-radio">
                <input 
                  type="radio" 
                  name="source" 
                  value="阿拉丁"
                  checked={radioSelectedCompetitor === '阿拉丁'}
                  onChange={handleRadioCompetitorChange} 
                />
                <span className="radio-circle"></span>
                阿拉丁
              </label>
              <label className="custom-radio">
                <input 
                  type="radio" 
                  name="source" 
                  value="麦克林"
                  checked={radioSelectedCompetitor === '麦克林'}
                  onChange={handleRadioCompetitorChange} 
                />
                <span className="radio-circle"></span>
                麦克林
              </label>
              <label className="custom-radio">
                <input 
                  type="radio" 
                  name="source" 
                  value="JK"
                  checked={radioSelectedCompetitor === 'JK'}
                  onChange={handleRadioCompetitorChange} 
                />
                <span className="radio-circle"></span>
                JK
              </label>
            </div>

          </div>
          <div className="d-lg-flex pt-3">
            <div className="dropdown-container">
              <input
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="使用Product No.进行搜索"
              />
            </div>
            <button className="search-btn" onClick={handleSearch}>
              <img src={SearchIcon} alt="Search" />
            </button>
          </div>
          {searchResults.length > 0 && (
              <>
                {filteredData.length > 0 && (
                  <div className="content-wrap mt-4 mb-4">
                    <div>
                    <p>productCode <span className="red">{filteredData[0].productCode}</span></p>
                      <div className="d-inline-block close">
                        <img id="open-popup-btn" onClick={togglePopup} src={close} style={{ width: '22px' }} alt="Close" />
                      </div>
                    </div>
                  </div>
                )}
                <div className="text-right mt-3 mb-3 d-flex" style={{ float: 'right' }}>
                  <button className="download" onClick={handleDownload}>
                    <img src={download} style={{ width: '22px' }} alt="Download" />
                    下载列表
                  </button>
                </div>
                <DataTable
                  filteredData={filteredData}
                  currentItems={currentItems}
                  purityInput={purityInput}
                  handlePurityInputChange={handlePurityInputChange}
                  sortByPurity={sortByPurity}
                  sizeInput={sizeInput}
                  handleSizeInputChange={handleSizeInputChange}
                  sortBySize={sortBySize}
                  selectedCompetitor={selectedCompetitor}
                  handleCompetitorChange={handleCompetitorChange}
                  getUniqueCompetitors={getUniqueCompetitors}
                  selectedBrand={selectedBrand}
                  handleBrandChange={handleBrandChange}
                  getUniqueBrands={getUniqueBrands}
                  marketPriceInput={marketPriceInput}
                  handleMarketPriceInputChange={handleMarketPriceInputChange}
                  handleMarketPriceSort={handleMarketPriceSort}
                  promoPriceInput={promoPriceInput}
                  handlePromoPriceInputChange={handlePromoPriceInputChange}
                  handlePromoPriceSort={handlePromoPriceSort}
                  FilterDate={FilterDate}
                  handleFilterDateChange={handleFilterDateChange}
                  selectedStockStatus={selectedStockStatus} // 新增部分
                  handleStockStatusChange={handleStockStatusChange} // 新增部分
                />
              </>
            )}
          <Popup 
            showPopup={showPopup} 
            togglePopup={togglePopup} 
            handleDelete={handleDelete} 
          />
           {filteredData.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductNo;
