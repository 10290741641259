import React from 'react';
import Switch from "../assets/switch.svg";

const DataTable = ({
  filteredData,
  currentItems,
  purityInput,
  handlePurityInputChange,
  sortByPurity,
  sizeInput,
  handleSizeInputChange,
  sortBySize,
  selectedCompetitor,
  handleCompetitorChange,
  getUniqueCompetitors,
  selectedBrand,
  handleBrandChange,
  getUniqueBrands,
  marketPriceInput,
  handleMarketPriceInputChange,
  handleMarketPriceSort,
  promoPriceInput,
  handlePromoPriceInputChange,
  handlePromoPriceSort,
  FilterDate,
  handleFilterDateChange,
  selectedStockStatus, // 添加这一行
  handleStockStatusChange // 添加这一行
}) => {
  return (
    <div className="mt-3">
      <table className="custom-table mt-3">
        <thead>
          <tr>
            <th>纯度</th>
            <th>规格</th>
            <th>供应商</th>
            <th>品牌</th>
            <th>同行价¥</th>
            <th>促销价¥</th>
            <th>是否现货</th> {/* 修改表头 */}
            <th>数据日期</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ backgroundColor: '#F2F3F4' }}>
            <td>
              <div className="custom-select-table-others-wrapper table-purity">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={purityInput}
                    onChange={handlePurityInputChange}
                  />
                  <div className="input-group-append">
                    <button onClick={sortByPurity}>
                      <img src={Switch} style={{ width: '14px' }} alt="Switch" />
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="custom-select-table-others-wrapper table-size">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={sizeInput}
                    onChange={handleSizeInputChange}
                  />
                  <div className="input-group-append">
                    <button onClick={sortBySize}>
                      <img src={Switch} style={{ width: '14px' }} alt="Switch" />
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="custom-select-table-others-wrapper">
                <select 
                  id="customSelectCompetitor" 
                  className="custom-select"
                  value={selectedCompetitor}
                  onChange={handleCompetitorChange}
                >
                  <option value="不限">不限</option>
                  {getUniqueCompetitors(filteredData).map((competitor, idx) => (
                    <option key={idx} value={competitor}>{competitor}</option>
                  ))}
                </select>
              </div>
            </td>
            <td>
              <div className="custom-select-table-brand-wrapper">
                <select 
                  id="customSelectBrand" 
                  className="custom-select" 
                  value={selectedBrand} 
                  onChange={handleBrandChange}
                >
                  <option value="">不限</option>
                  {getUniqueBrands(filteredData).map((brand, idx) => (
                    <option key={idx} value={brand}>{brand}</option>
                  ))}
                </select>
              </div>
            </td>
            <td>
              <div className="custom-select-wrapper table-price">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={marketPriceInput}
                    onChange={handleMarketPriceInputChange}
                  />
                  <div className="input-group-append">
                    <button onClick={handleMarketPriceSort}>
                      <img src={Switch} style={{ width: '14px' }} alt="Switch" />
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="custom-select-wrapper table-price">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={promoPriceInput}
                    onChange={handlePromoPriceInputChange}
                  />
                  <div className="input-group-append">
                    <button onClick={handlePromoPriceSort}>
                      <img src={Switch} style={{ width: '14px' }} alt="Switch" />
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="custom-select-wrapper table-stock-status">
                <select
                  className="custom-select"
                  value={selectedStockStatus}
                  onChange={handleStockStatusChange}
                >
                  <option value="">不限</option>
                  <option value="是">是</option>
                  <option value="否">否</option>
                </select>
              </div>
            </td>
            <td>
              <div className="d-inline-block custom-select-wrapper-date">
                <input
                  type="date"
                  className="form-control"
                  value={FilterDate}
                  onChange={handleFilterDateChange}
                />
              </div>
            </td>
          </tr>
          {filteredData.length > 0 && currentItems.map((item, index) => (
            <tr key={index}>
              <td>{item.purity}</td>
              <td>{item.size}</td>
              <td>{item.competitor}</td>
              <td>{item.brand}</td>
              <td>{item.marketPrice}</td>
              <td>{item.promoPrice}</td>
              <td>{item.stock > 0 ? '是' : '否'}</td> 
              <td>{item.dataDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
