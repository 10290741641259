import React from 'react';

import "../styles/style.css";
import Logo from "../assets/logo.png";
import MemberIcon from "../assets/member.svg";
import SearchIcon from "../assets/search-icon.svg";

function Header() {
  return (
    <header>
    <div className="container">
      <div className="d-flex justify-content-between">
        <div className="d-flex nav-left">
        <img className="d-inline-block align-top logo" src={Logo} ></img>
        </div>
        <div className="d-flex nav-right justify-content-center align-items-center">
          <p className="mr-2">Username141...</p>
          <div className="col-lg-3">
          <img className="d-inline-block align-top" src={MemberIcon} ></img>
          </div>
        </div>
      </div>
    </div>
  </header>
  );
}

export default Header;
